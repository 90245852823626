<template>

    
  <HelloWorld msg=""/>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  }
}


</script>

<style>
@import url(./assets/css/jquery.fullpage.css);
	html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video {
	font:inherit;
	font-size:100%;
	margin:0;
	padding:0;
	vertical-align:baseline;
	border:0;
}
article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section,main {
	display:block;
}
body {
	line-height:1;
}
ol,ul {
	list-style:none;
}
blockquote,q {
	quotes:none;
}
blockquote:before,blockquote:after,q:before,q:after {
	content:'';
	content:none;
}
table {
	border-spacing:0;
	border-collapse:collapse;
}
html {
	font-size:62.5%;
}
body {
	font-family:"Helvetica Neue","Lucida Grande",Arial,Verdana,sans-serif;
	font-size:1.4rem;
	font-weight:normal;
	font-weight:400;
	font-style:normal;
	line-height:1.8;
	position:absolute;
	height:100%;
	letter-spacing:0;
	color:#FFFFFF;
	background:#191c27;
	transition:all 0.3s ease;
	-webkit-font-smoothing:antialiased;
	-moz-osx-font-smoothing:grayscale;
}
body,html {
	overflow:hidden;
	width:100%;
	margin:0;
	padding:0;
	-webkit-tap-highlight-color:transparent;
}
a {
	cursor:pointer;
	text-decoration:none;
	color:#FFFFFF;
	transition:all 0.3s ease-in-out;
}
a:hover {
	text-decoration:none !important;
	color:#5400eb;
	outline:none !important;
}
a:active,a:focus {
	text-decoration:none !important;
	color:#FFFFFF;
	outline:none !important;
}
button {
	cursor:pointer;
	transition:all 0.3s ease-in-out;
}
button:hover,button:active,button:focus {
	text-decoration:none !important;
	color:#FFFFFF;
	outline:none !important;
}
strong,b {
	font-weight:700;
}
em,i {
	font-style:italic;
}
p {
	font-size:1.5rem;
	font-weight:400;
	line-height:1.8;
	margin:0;
	color:#FFFFFF;
}
img {
	transition:all 0.2s ease-in-out;
}
h1,h2,h3,h4,h5,h6 {
	font-family:"Helvetica Neue","Lucida Grande",Arial,Verdana,sans-serif;
	font-weight:400;
	line-height:1;
	margin:0 0 1.5rem 0;
	color:#FFFFFF;
}
h1 a,h2 a,h3 a,h4 a,h5 a,h6 a {
	text-decoration:none;
	color:inherit;
}
h1 small,h2 small,h3 small,h4 small,h5 small,h6 small {
	font-family:"Helvetica Neue","Lucida Grande",Arial,Verdana,sans-serif;
	font-size:1.6rem;
	font-style:italic;
	display:block;
	margin-top:.8rem;
	color:rgba(255,255,255,0.7);
}
h1 {
	font-size:8rem;
	font-weight:400;
	color:#FFFFFF;
	line-height:1.1;
}
h2 {
	font-size:7rem;
	font-weight:700;
}
h2.home-h2 {
	font-family:"Helvetica Neue","Lucida Grande",Arial,Verdana,sans-serif;
	font-size:2rem;
	font-style:italic;
	display:block;
	margin-top:.8rem;
	color:rgba(255,255,255,0.85);
}
h2 .number {
	color:#5f05ff;
}
h3 {
	font-size:2.3rem;
}
h4 {
	font-size:1.8rem;
}
h5 {
	font-size:1.6rem;
}
h6 {
	font-size:1.2rem;
}
a,button,input,select,textarea {
	outline:0 none;
}
sub {
	font-size:.8em;
	position:relative;
	top:.5em;
}
sup {
	font-size:.8em;
	position:relative;
	top:-.5em;
}
.clear {
	clear:both;
}
.display-none {
	display:none !important;
}
.align-left {
	text-align:left;
}
.align-center {
	text-align:center;
}
.align-right {
	text-align:right;
}
.no-margin-bottom {
	margin-bottom:0;
}
.opacity-0 {
	visibility:hidden !important;
	opacity:0 !important;
}
.opacity-03 {
	opacity:.3 !important;
}
.opacity-1 {
	visibility:visible !important;
	opacity:1 !important;
}
.index-999 {
	z-index:-999 !important;
}
.ibtn {
	font-family:"Helvetica Neue","Lucida Grande",Arial,Verdana,sans-serif;
	font-size:1.2rem;
	font-weight:700;
	line-height:48px;
	display:inline-block;
	float:left;
	height:5rem;
	line-height:5rem;
	margin-top:2.5rem;
	margin-right:1rem;
	padding:0 3rem;
	text-align:center;
	letter-spacing:.1rem;
	text-transform:uppercase;
}
.ibtn.light-btn {
	position:absolute;
	bottom:0;
	left:50%;
	bottom:17px;
	margin-left:0;
	color:white;
	border:none;
	background:#5f05ff;
	border-radius:35px;
	overflow:hidden;
	transform:translateX(-50%);
	transition:all 0.1s cubic-bezier(0.77,0,0.175,1);
}
.ibtn.light-btn i {
	font-size:2rem;
}
.ibtn.light-btn:after {
	position:absolute;
	z-index:-1;
	top:0;
	left:0;
	width:100%;
	height:100%;
	content:'';
	background:#FFFFFF;
	transition:all 0.1s cubic-bezier(0.77,0,0.175,1);
	transform:scale(0);
	border-radius:35px;
}
.ibtn.light-btn:hover {
	color:#191c27;
	background:transparent;
}
.ibtn.light-btn:hover:after {
	left:0;
	transform:scale(1);
	border-radius:35px;
}
.ibtn.light-btn.blink:before {
	position:absolute;
	left:0;
	top:0;
	display:block;
	width:60px;
	height:100%;
	background:rgba(255,255,255,0.6);
	filter:blur(5px);
	animation:shine 3s infinite linear;
	content:'';
	opacity:.6;
}
.ibtn.back-home {
	background:rgba(25,28,39,0.3);
}
@keyframes shine {
	0% {
	transform:translateX(-100px) skewX(-15deg);
}
10%,100% {
	transform:translateX(300px) skewX(-15deg);
}
}.cd-words-wrapper {
	display:inline-block;
	position:relative;
	text-align:center;
	width:100% !important;
}
.cd-words-wrapper b {
	display:inline-block;
	position:absolute;
	white-space:nowrap;
	left:0;
	top:0;
}
.cd-words-wrapper b.is-visible {
	position:relative;
}
.no-js .cd-words-wrapper b {
	opacity:0;
}
.no-js .cd-words-wrapper b.is-visible {
	opacity:1;
}
.cd-headline.zoom .cd-words-wrapper {
	perspective:300px;
}
.cd-headline.zoom .cd-words-wrapper span.highlight {
	color:#5f05ff;
}
.cd-headline.zoom b {
	opacity:0;
	font-weight:400;
}
.cd-headline.zoom b.is-visible {
	opacity:1;
	animation:zoom-in .8s;
}
.cd-headline.zoom b.is-hidden {
	position:absolute;
	left:50%;
	transform:translateX(-50%);
	animation:zoom-out .8s;
}
@keyframes zoom-in {
	0% {
	opacity:0;
	transform:translateZ(100px);
}
100% {
	opacity:1;
	transform:translateZ(0);
}
}@keyframes zoom-out {
	0% {
	opacity:1;
	transform:translateZ(0) translateX(-50%);
}
100% {
	opacity:0;
	transform:translateZ(-100px) translateX(-50%);
}
}.vegas-overlay {
	opacity:.5;
	background:transparent url() center center repeat;
}
#fullpage {
	left:0;
	width:100vw;
	transition:all 0.4s cubic-bezier(0.215,0.61,0.355,1);
}
.full-overlay {
	position:absolute;
	top:0;
	left:0;
	z-index:0;
	width:100%;
	height:100%;
	background:rgba(0,0,0,0.4);
	transition:all 0.3s cubic-bezier(0.215,0.61,0.355,1);
}
.full-overlay.on-canvas {
	background:rgba(0,0,0,0.2);
}
.full-overlay.darky {
	background:rgba(0,0,0,0.8);
}
.full-overlay.darky.on-canvas {
	background:rgba(0,0,0,0.6);
}
.fp-section {
	top:0;
	bottom:0;
	right:0;
	position:absolute;
	left:0;
	visibility:hidden;
	height:0;
	opacity:0;
	transition:all 0.3s cubic-bezier(0.215,0.61,0.355,1);
}
.fp-section .inside-content {
	transform:translateY(0) scale(0.8);
	transition:all 0.3s cubic-bezier(0.215,0.61,0.355,1);
	opacity:0;
}
.fp-section.active {
	visibility:visible;
	opacity:1;
}
.fp-section.active .inside-content {
	opacity:1;
	transform:translateY(0) scale(1);
}
.fp-section.color-1 {
	background:#263248 !important;
}
.fp-section.color-2 {
	background:#EFC94C !important;
}
.fp-section.color-3 {
	background:#DF5A49 !important;
}
.inside-section {
	position:relative;
	display:table;
	width:100%;
	height:calc(100% - 200px);
	border-radius:0;
	background:transparent;
	display:flex;
	justify-content:center;
	align-items:center;
}
#fp-nav {
	top:50vh;
	left:0 !important;
	width:200px;
	margin-top:0 !important;
	transform:translateY(-50%);
	transition:all 0.8s ease;
}
#fp-nav ul li {
	width:100%;
	height:24px;
	margin:12px 0;
	transition:all 0.4s ease;
}
#fp-nav ul li .fp-tooltip {
	font-family:"Helvetica Neue","Lucida Grande",Arial,Verdana,sans-serif;
	font-weight:400;
	z-index:10;
	top:-10px;
	right:auto;
	left:75px;
	overflow:visible;
	text-transform:uppercase;
	color:#FFFFFF;
	transition:all 0.4s ease;
}
#fp-nav ul li a {
	line-height:1;
}
#fp-nav ul li a span {
	top:0;
	left:0;
	left:45px;
	width:24px;
	height:24px;
	margin:0;
	background:transparent;
	text-align:center;
	line-height:24px;
	border-radius:50%;
	transition:all 0.4s ease;
}
#fp-nav ul li a span:after {
	position:absolute;
	top:-3px;
	left:-3px;
	content:'';
	width:24px;
	height:24px;
	border-radius:50%;
	font-size:24px;
	line-height:24px;
	z-index:-100;
	margin:0;
	font-family:'FontAwesome';
	content:'\f13a';
	color:#FFFFFF;
	transition:all 0.4s ease;
	transform-origin:center;
}
#fp-nav ul li a.active span {
	left:45px;
	width:24px;
	height:24px;
	margin:0;
}
#fp-nav ul li a.active span:after {
	opacity:1;
	transform:rotateZ(-90deg);
	color:#5f05ff;
}
#fp-nav ul li a.active .fp-tooltip {
	animation:fade-tooltip 1.2s 0.4s ease;
	animation-iteration-count:1;
}
#fp-nav ul li:hover a span,#fp-nav ul li:hover a.active span {
	left:45px;
	width:24px;
	height:24px;
	margin:0;
	-webkit-border-radius:100%;
	-moz-border-radius:100%;
	-ms-border-radius:100%;
	border-radius:100%;
}
#fp-nav ul li:hover a span:after {
	opacity:1;
	transform:rotateZ(-90deg);
	color:#5f05ff;
}
#fp-nav ul li:hover .fp-tooltip {
	top:2px;
	opacity:1;
	color:#FFFFFF;
}
@keyframes fade-tooltip {
	0% {
	opacity:0;
}
20% {
	top:2px;
	opacity:1;
}
80% {
	top:2px;
	opacity:1;
}
100% {
	top:10px;
	opacity:0;
}
}#subscribe p {
	font-weight:400;
}
#subscribe #notifyMe {
	margin:0;
	margin-top:2em;
}
#subscribe #notifyMe::placeholder {
	font-weight:400;
	color:rgba(25,28,39,0.6) !important;
}
#subscribe #notifyMe .form-group {
	position:relative;
}
#subscribe #notifyMe .form-group .form-control {
	font-weight:700;
	max-width:27rem;
	float:left;
	height:50px;
	margin:0 0 2rem 0;
	padding:0 1em;
	text-align:left;
	color:#191c27;
	border:none;
	border-right:none;
	outline:medium none;
	background:#FFFFFF;
	box-shadow:none;
	border-radius:35px;
	transition:all 0.1s cubic-bezier(0.77,0,0.175,1);
}
#subscribe #notifyMe .form-group .form-control:hover,#subscribe #notifyMe .form-group .form-control:focus {
	box-shadow:none;
}
#subscribe #notifyMe .form-group button.submit {
	font-family:"Helvetica Neue","Lucida Grande",Arial,Verdana,sans-serif;
	font-size:1.2rem;
	font-weight:700;
	line-height:48px;
	display:block;
	height:50px;
	margin:0 0 0 1rem;
	padding:0 3rem;
	text-align:center;
	letter-spacing:.1rem;
	text-transform:uppercase;
	color:#FFFFFF;
	border:none;
	border-left:none;
	background:#5f05ff;
	width:auto;
	float:left;
	position:relative;
	overflow:hidden;
	border-radius:35px;
}
#subscribe #notifyMe .form-group button.submit:after {
	position:absolute;
	z-index:-1;
	top:0;
	left:0;
	width:100%;
	height:100%;
	content:'';
	background:#FFFFFF;
	transition:all 0.1s cubic-bezier(0.77,0,0.175,1);
	transform:scale(0);
	border-radius:35px;
}
#subscribe #notifyMe .form-group button.submit:hover {
	color:#191c27;
	border-color:rgba(255,255,255,0.8);
	background:transparent;
}
#subscribe #notifyMe .form-group button.submit:hover:after {
	left:0;
	transform:scale(1);
	border-radius:35px;
}
.spam-news {
	font-size:1.1rem;
	position:absolute;
	z-index:200;
	bottom:-2rem;
	left:0;
	width:100%;
	text-align:left;
	color:rgba(255,255,255,0.7);
}
.brand-logo {
	position:relative;
	width:15rem;
	height:4.9rem;
}
.inside-content {
	position:relative;
	z-index:10;
	left:0;
	visibility:visible;
	padding:0 100px;
	text-align:center;
}
.inside-content.double-col {
	text-align:left;
}
.inside-content .text {
	max-width:60rem;
	margin:2rem auto;
	position:relative;
}
.inside-content .text li {
	font-weight:700;
}
.inside-content .text li .fa {
	color:#5f05ff;
}
#contact-form {
	margin:3rem auto 0;
	max-width:500px;
}
#contact-form::placeholder {
	font-family:"Helvetica Neue","Lucida Grande",Arial,Verdana,sans-serif;
	font-weight:400;
	color:rgba(255,255,255,0.7) !important;
}
#contact-form .form-group:hover .icon-fields {
	color:#5f05ff;
}
#contact-form .icon-fields {
	position:absolute;
	top:12px;
	left:25px;
	color:rgba(255,255,255,0.15);
	transition:all 0.3s cubic-bezier(0.785,0.135,0.15,0.86);
}
#contact-form .icon-fields.active {
	color:#5f05ff;
}
#contact-form .form-group {
	padding-left:35px;
	border-bottom:1px solid rgba(255,255,255,0.15);
}
#contact-form .form-group.form-group-select:after,#contact-form .form-group.form-group-select:before {
	border:solid transparent;
	border-width:.3em;
	content:"";
	position:absolute;
	right:2.5rem;
	top:20%;
	z-index:1;
}
#contact-form .form-group.form-group-select:before {
	border-top-color:#FFFFFF;
	margin-top:.7rem;
}
#contact-form .form-group.form-group-select:after {
	border-bottom-color:#FFFFFF;
	margin-top:-.6em;
}
#contact-form .form-control {
	font-weight:700;
	width:100%;
	height:40px;
	padding:6px 8px 6px 0;
	text-align:left;
	color:white;
	border:none;
	outline:medium none;
	background:transparent;
	box-shadow:none;
	transition:all 0.1s cubic-bezier(0.77,0,0.175,1);
	border-radius:0;
}
#contact-form .form-control:hover,#contact-form .form-control:focus {
	box-shadow:none;
}
#contact-form select.form-control {
	font-weight:700;
	color:rgba(255,255,255,0.8);
	-moz-appearance:window;
	-webkit-appearance:none;
}
#contact-form select.form-control.no-selection {
	font-family:"Helvetica Neue","Lucida Grande",Arial,Verdana,sans-serif;
	font-weight:400;
	color:rgba(255,255,255,0.7) !important;
}
#contact-form textarea.form-control {
	line-height:2;
	min-height:150px;
}
#contact-form span.sub-text {
	font-size:1rem;
	position:absolute;
	right:1rem;
	bottom:-1rem;
	color:rgba(255,255,255,0.7);
}
#contact-form #ios {
	position:absolute;
	visibility:hidden;
}
#contact-form .button {
	position:relative;
	top:0;
	display:block;
	float:left;
	width:70px;
	height:34px;
	margin:1rem 0 1rem;
	cursor:pointer;
	background:#FFFFFF;
	will-change:background;
}
#contact-form .button.ios {
	position:relative;
	border-radius:100px;
	transition:background,0.25s cubic-bezier(0.77,0,0.175,1);
}
#contact-form .button.ios:before {
	position:absolute;
	top:0;
	left:0;
	display:block;
	width:100%;
	height:100%;
	content:'';
	background-color:#FFFFFF;
	box-shadow:inset 0 10px 30px rgba(25,28,39,0.2);
	border-radius:100px;
	transition:background,0.25s cubic-bezier(0.77,0,0.175,1);
	will-change:transform;
	will-change:background;
}
#contact-form .button.ios:after {
	position:absolute;
	top:2px;
	left:2px;
	display:block;
	width:30px;
	height:30px;
	content:'';
	background-color:#FFFFFF;
	box-shadow:0 10px 30px rgba(0,0,0,0.1);
	border-radius:100px;
	transition:background,0.25s cubic-bezier(0.77,0,0.175,1);
}
#contact-form input[type=checkbox]:checked+label.ios {
	background-color:#40E699;
	transition:0s background,0s cubic-bezier(0.77,0,0.175,1);
}
#contact-form input[type=checkbox]:checked+label.ios:after {
	transform:translateX(35px);
}
#contact-form input[type=checkbox]:checked+label.ios:active:after {
	transform:translateX(10px);
}
#contact-form input[type=checkbox]:checked+label.ios:before {
	transform:translateX(10px) scale(0.1);
}
#contact-form label.check-news {
	font-family:"Helvetica Neue","Lucida Grande",Arial,Verdana,sans-serif;
	font-size:1.4rem;
	font-style:italic;
	display:block;
	float:left;
	margin-top:1.3rem;
	margin-left:.6rem;
	color:rgba(255,255,255,0.8);
}
#contact-form button#valid-form {
	font-family:"Helvetica Neue","Lucida Grande",Arial,Verdana,sans-serif;
	font-size:1.2rem;
	font-weight:700;
	line-height:48px;
	display:inline-block;
	height:50px;
	margin-top:2rem;
	padding:0 3rem;
	letter-spacing:.1rem;
	text-transform:uppercase;
	color:#FFFFFF;
	border:none;
	background:#5f05ff;
	position:relative;
	overflow:hidden;
	border-radius:35px;
}
#contact-form button#valid-form:after {
	position:absolute;
	z-index:-1;
	top:0;
	left:0;
	width:100%;
	height:100%;
	content:'';
	background:#FFFFFF;
	transition:all 0.1s cubic-bezier(0.77,0,0.175,1);
	transform:scale(0);
	border-radius:35px;
}
#contact-form button#valid-form:hover {
	color:#191c27;
	background:transparent;
}
#contact-form button#valid-form:hover:after {
	left:0;
	transform:scale(1);
	border-radius:35px;
}
.social-nav {
	z-index:100;
	display:inline-block;
	margin-top:30px;
	opacity:1;
	position:absolute;
	top:0;
	right:25px;
}
.social-nav ul {
	margin:0;
	padding:0;
}
.social-nav ul li {
	position:relative;
	display:block;
	float:left;
	margin:0 5px 0;
	text-align:center;
	transition:all 0.3s cubic-bezier(0,0,0.58,1);
}
.social-nav ul li a {
	font-size:.9em;
	line-height:35px !important;
	position:relative;
	display:inline-block;
	width:35px;
	height:35px;
	margin:0 5px;
	text-align:center;
	color:#FFFFFF;
	transition:all 0.3s cubic-bezier(0,0,0.58,1);
	-webkit-backface-visibility:hidden;
}
.social-nav ul li a:before {
	font-size:1.5em;
	position:absolute;
	top:0;
	left:0;
	display:inline-block;
	width:100%;
	height:100%;
	content:'';
	border:1px solid rgba(255,255,255,0.3);
	background:transparent;
	border-radius:2px;
	transition:all 0.4s cubic-bezier(0.77,0,0.175,1);
	transform:rotate(45deg);
}
.social-nav ul li a:hover {
	color:#FFFFFF;
}
.social-nav ul li a:hover:before {
	border:none;
	background:#5f05ff;
	transform:rotate(135deg);
}
.social-nav ul li a i {
	position:relative;
	top:0;
	left:0;
}
.copyright {
	position:absolute;
	z-index:60;
	right:25px;
	bottom:1rem;
	text-align:right;
}
.copyright p {
	font-size:1.1rem;
	color:#c6c9ce;
}
.copyright p a {
	color:#FFFFFF;
}
.copyright #dni {
	color:#FFFFFF;
}
.copyright p a:hover {
	color:#6f1fff;
}
#bg {
	position:fixed;
	top:0;
	left:0;
	width:100%;
	height:100%;
	z-index:-1;
}
#bg canvas {
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
}
#container {
	height:100%;
	position:absolute;
	width:100%;
	left:0;
	top:0;
}
@media only screen and (max-width:1280px) {
	h1 {
	font-size:7rem;
}
h2 {
	font-size:5rem;
}
#subscribe #notifyMe .form-group .form-control {
	float:none;
	margin-bottom:1.5rem;
}
#subscribe #notifyMe .form-group button.submit {
	float:none;
	margin-left:0;
}
.spam-news {
	bottom:-3rem;
}
}@media only screen and (max-width:991px) {
	h1 {
	font-size:6rem;
}
.fp-section {
	min-height:100vh !important;
	border-bottom:1px solid rgba(255,255,255,0.2);
}
.fp-section {
	position:relative;
	opacity:1 !important;
	top:auto;
	visibility:visible !important;
}
.fp-section .inside-content {
	transform:scale(1) translateY(0) !important;
	opacity:1 !important;
}
.fp-section:not(#section0) {
	min-height:0 !important;
	height:auto !important;
	background:#191c27;
}
.fp-section:not(#section0) .fp-tableCell {
	height:auto !important;
	padding-top:10rem;
	padding-bottom:10rem;
	display:inherit;
	vertical-align:inherit;
}
.fp-section:not(#section0) .inside-content {
	text-align:left;
}
#contact-form {
	margin:3rem 0 0;
}
.full-overlay {
	max-height:50%;
}
#fp-nav {
	position:fixed;
}
.spam-news {
	position:relative;
	bottom:auto;
}
}@media only screen and (max-width:768px) {
	.inside-content {
	padding:0 5rem;
}
.inside-content .text {
	margin:2rem 0 4rem;
}
#fp-nav {
	display:none;
}
.fp-section:not(#section0) .inside-content {
	width:100%;
}
#fp-nav {
	position:fixed;
	top:3rem;
	transform:translateY(0);
}
.cd-headline.zoom b {
	white-space:inherit;
}
@keyframes zoom-out {
	0% {
	display:none;
}
}}@media only screen and (max-width:480px) {
	h1 {
	font-size:4rem;
	margin-top:1rem;
}
h2 {
	font-size:4rem;
}
h2.home-h2 {
	font-size:1.6rem;
}
.copyright {
	position:relative;
	z-index:60;
	right:auto;
	bottom:auto;
	text-align:center;
	margin:2rem 0;
}
.social-nav {
	position:relative;
	top:auto;
	right:auto;
	width:100%;
	text-align:center;
}
.social-nav ul {
	display:inline-block;
}
.form-control {
	font-size:1.2rem;
}
.fp-section:not(#section0) .fp-tableCell {
	padding-top:5rem;
	padding-bottom:5rem;
}
.inside-content {
	padding:0 3rem;
}
#subscribe #notifyMe .form-group .form-control {
	text-align:center;
	max-width:none;
}
#subscribe #notifyMe .form-group button.submit {
	width:100%;
}
#contact-form {
	margin-top:2rem;
	margin-bottom:3rem;
}
#contact-form .ios {
	float:none;
	margin:1rem auto;
}
#contact-form label.check-news {
	margin-top:0;
	width:100%;
	text-align:center;
}
#contact-form button#valid-form {
	width:100%;
	margin-top:1.5rem;
}
}@media screen and (max-width:667px) and (orientation:landscape) {
	#section0 .inside-section {
	padding-top:12rem;
	padding-bottom:12rem;
}
}
</style>
