<template>

  <div>
    <!-- <link rel="stylesheet" href="https://www.h2fast.cn/css/style.css"> -->
  
        <div id="bg">
        <canvas></canvas>
        <canvas></canvas>
        <canvas></canvas>
    </div>

      
    

    <span class="full-overlay on-canvas"></span>
    <div id="fullpage">
      <div style="text-align:center;">
  
 <nav class="tpm-prod-hero__dropdown-wrap">
   <div class="tpm-prod-hero__event" v-if="message">
     {{ message }} | <a id="myLink" href="#" class="tp-link tpm-link tp-link--white-arrow"><span class="tp-link__inner">立即前往</span></a>
   </div>
 </nav>
 
  
</div>
        <!-- 主页 -->
        <div class="section active" id="section0">
            <div class="inside-section">
                <div class="inside-content">
                    <img src="//cvmi.cn/logo.png" class="brand-logo" alt="Logo" />
                    <h1 class="cd-headline zoom">
                        <span class="cd-words-wrapper">
                            <b class="is-visible"><span class="highlight">十赫兹</span> 科技</b>
                            <b><span class="highlight">Ten</span> hertz</b>
                            <!--<b><span class="highlight">诚信</span> 稳定 <span class="highlight">安全</span></b>-->
                        </span>
                    </h1>
                    <div class="text">
                        <h2 class="home-h2">不管风吹浪打，胜似闲庭信步。</h2>
                    </div>
                </div>
            </div>
            <a class="ibtn light-btn blink" href="#2" title="关于">关于我们</a>
        </div>
        <!-- 关于我们 -->
        <div class="section" id="section1">
            <div class="inside-section">
                <div class="inside-content double-col container">
                    <div class="row">
                        <div class="col-xs-12 col-md-6 col-lg-5 with-border">
                            <h2>
                                1<span class="number">.</span> 企业理念<small>Do technology, we are serious.</small>
                            </h2>
                            <div class="text">
                                <p class="subtitle">
                                    十赫兹科技是昆明十赫兹科技有限公司的企业简称，我们始创于2021年，专注于企业建站，软件开发，技术咨询等产品服务；做技术，我们是认真的。
                                    <!--<br/><br/>旗下站点：<a href="" target="_blank" title="" rel="noopener"><strong></strong></a><a href="" target="_blank" title="" rel="noopener"><strong></strong></a>-->
                                </p>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-6 col-md-push-0 col-lg-6 col-lg-push-1">
                            <h2>
                                2<span class="number">.</span> 加入我们<small>I will always be here for you.</small>
                            </h2>
                            <div class="text">
                                <ul>
                                    <li><i class="fa fa-check"></i> 5年以上开发经验；</li>
                                    <li><i class="fa fa-check"></i> 编码规范、结构清晰、代码优雅；</li>
                                    <li><i class="fa fa-check"></i> 有良好的沟通能力、责任心、为人开朗；</li>
                                </ul>
                                <div id="subscribe">
                                    <form id="notifyMe">
                                        <div class="form-group">
                                            <div class="controls">
                                                <input type="text" id="mail-sub" name="email" placeholder="留下您的邮箱" onfocus="this.placeholder = ''" onblur="this.placeholder = '留下您的邮箱'" class="form-control email srequiredField"/>
                                                <button type="button" class="btn btn-lg submit"> 申请加入</button>
                                                <div class="clear"></div>
                                            </div>
                                        </div>
                                    </form>
                                    <p class="spam-news">
                                        <i class="fa fa-lock"></i> 你和我的安全对话，只是开始的第一步。
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <a class="ibtn light-btn hidden-xs hidden-sm" href="#3" title="留言">有话要说</a>
        </div>
         <!-- 访客留言 -->
        <div class="section" id="section2">
            <div class="inside-section">
                <div class="inside-content container">
                    <h2>
                        Hello,你好!<small>十分期待能收到您的留言，大家一起合作共赢！</small>
                    </h2>
                    <div class="text">
                        <form id="contact-form" method="post" action="message.php" name="contact-form" data-name="Contact Form">
    <div class="row">
        <div class="col-xs-12 col-sm-12 col-lg-12">
            <div class="form-group">
                <i class="fa fa-user icon-fields"></i>
                <input type="text" id="name" class="form form-control" placeholder="怎么称呼？" onfocus="this.placeholder = ''" onblur="this.placeholder = '怎么称呼？'" name="name" data-name="Name" required/>
            </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-lg-12">
            <div class="form-group">
                <i class="fa fa-envelope icon-fields"></i>
                <input type="email" id="email" class="form form-control" placeholder="留下您的邮箱地址吧！" onfocus="this.placeholder = ''" onblur="this.placeholder = '留下您的邮箱地址吧！'" name="email" data-name="Email Address" required/>
            </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-lg-12">
            <div class="form-group">
                <i class="fa fa-pencil icon-fields"></i>
                <input type="text" id="message" class="form textarea form-control" placeholder="有什么想对我说的吗？" onfocus="this.placeholder = ''" onblur="this.placeholder = '有什么想对我说的吗？'" name="message" data-name="Text Area" required/>
            </div>
            <span class="sub-text">良言一句三冬暖，恶语伤人六月寒...</span>
        </div>
    </div>
    <button type="button" id="valid-form" class="btn btn-color">提交留言</button>
</form>

                    </div>
                </div>
            </div>
            <a class="ibtn light-btn back-home hidden-xs hidden-sm" href="#1" title="主页"><i class="fa fa-home"></i></a>
        </div>
    </div>
    <!-- 社交媒体 -->
    <div class="social-nav">
        <ul>
            <li>
                <a href="" target="_blank" title="联系QQ" rel="noopener"><i class="fa fa-qq"></i></a>
            </li>
            <li>
                <a href="" target="_blank" title="关注Github" rel="noopener"><i class="fa fa-github"></i></a>
            </li>
            <li>
                <a href="" target="_blank" title="关注微博" rel="noopener"><i class="fa fa-weibo"></i></a>
            </li>
            <li>
                <a href="" target="_blank" title="关注博客" rel="noopener"><i class="fa fa-rss-square"></i></a>
            </li>
        </ul>
    </div>
    
    <!-- 底部版权 -->
    <footer class="copyright">
        <p>
            <strong>已稳定运营 <span id="dni">999</span> 天</strong><br/><a href="https://beian.miit.gov.cn/" target="_blank" title="" rel="noopener">滇ICP备2023002656号</a><br/>©2021-2022 <a href="https://cvmi.cn" title="十赫兹科技 - Ten Hertz Technology">昆明十赫兹科技有限公司</a> 版权所有
        </p>
    </footer>
    
  </div>
  
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      message: '',
      url: ''
    }
  },
  mounted() {
  axios.get('http://test-1.cvmi.cn/api/notices')
    .then(response => {
      // 假设返回的是一个公告列表，我们取第一个公告
      let firstNotice = response.data[0]; 
      this.message = firstNotice.title;
      let url = firstNotice.content; // 如果content是一个URL
      let linkElement = document.getElementById('myLink');
      linkElement.href = url;
    })
    .catch(error => {
      console.log(error);
    });
}


};


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.tpm-prod-hero__dropdown-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
}


</style>
